






































































import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
export interface IRowDataCfg {
  title: string;
  content: string;
  btnTitle: string;
}

@Component({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {
  private rowListData: IRowDataCfg[] = [
    {
      title: "安卓软件开发-列表刷新空间详情",
      content: "安卓软件开发-列表刷新空间详情",
      btnTitle: "1 元一条",
    },
    {
      title: "安卓软件开发-图片滚动控件详解",
      content: "安卓软件开发-图片滚动控件详解",
      btnTitle: "1 元一条",
    },
    {
      title: "安卓软件开发-网络通信模块研究",
      content: "安卓软件开发-网络通信模块研究",
      btnTitle: "1 元一条",
    },
    {
      title: "iOS软件开发-自定义导航栏分析",
      content: "iOS软件开发-自定义导航栏分析",
      btnTitle: "1 元一条",
    },
    {
      title: "iOS软件开发-内存优化常用方法总结",
      content: "iOS软件开发-内存优化常用方法总结",
      btnTitle: "1 元一条",
    },
    {
      title: "iOS软件开发-深入研究数据存储方案",
      content: "iOS软件开发-深入研究数据存储方案",
      btnTitle: "1 元一条",
    },
  ];
  private paypanelshow: boolean = false;
  private loginform: {
    username: string;
    psd: string;
    psdAgain: string;
    agree: boolean;
  } = {
    username: "",
    psd: "",
    psdAgain: "",
    agree: false,
  };
  private radio: string = "";

  btnAction() {
    console.log("btn click");
    this.paypanelshow = true;
  }

  dialogAction() {
    console.log(this.loginform);
    this.paypanelshow = false;
  }
}
